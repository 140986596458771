/**
 * Auth0 Get User
 * Get the user from the Auth0 Database
 */
import { navigate } from "gatsby"

async function getUserRecord(id, token) {
  const response = await fetch(`/.netlify/functions/get-user?id=${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })

  if (response.ok) {
    return await response.json()
  } else {
    if (response.status === 401) {
      console.log("session expired, logging out")
      navigate("/logout")
    }
  }
}

export default getUserRecord
