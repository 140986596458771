/**
 * Verify User
 * Ensure the user has the correct permissions and a
 * valid Auth0 session
 */

import getUserRecord from "../../bssr-api/auth0/get-user"

export const verifyUser = async ({ sub, token }) => {
  try {
    if (sub && token) {
      const res = await getUserRecord(sub, token)

      return {
        ...res,
        idToken: token,
      }
    }

    return undefined
  } catch (err) {
    console.error("An error occured in verifyUser()", err)
  }
}
