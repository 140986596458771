import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { verifyUser } from "../lib/auth/verify-user"
import { useAuth } from "../providers/auth/AuthProvider"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageLoader from "../components/page-loader"
import PortableText from "../components/portable-text"
import { Button } from "../components/button"

function VideoTemplate({ data }) {
  const { page } = data
  const { getIdTokenClaims } = useAuth0()

  const [loading, setLoading] = useState(true)
  const [showMessage, setMessage] = useState(false)
  const [showErrMessage, setErrMessage] = useState(false)
  const [state] = useAuth()

  useEffect(() => {
    if (typeof window !== "undefined" && page.restricted) {
      if (!state.app_metadata) {
        async function getUserData() {
          try {
            const claims = await getIdTokenClaims()

            if (!claims) {
              setMessage(true)
              setLoading(false)
            } else {
              const idToken = claims.__raw
              const res = await verifyUser({ sub: claims.sub, token: idToken })

              if (res && res.data) {
                if (res.data.app_metadata.account_type === "free") {
                  setMessage(true)
                  setLoading(false)
                } else {
                  setLoading(false)
                }
              } else {
                console.error("getUserData() issue with res", res)
                setErrMessage(true)
                setLoading(false)
              }
            }
          } catch (err) {
            console.error("getUserData() error", err)
            setErrMessage(true)
            setLoading(false)
          }
        }

        getUserData()
      } else {
        if (state.app_metadata.account_type === "free") {
          setMessage(true)
          setLoading(false)
        } else {
          setErrMessage(true)
          setLoading(false)
        }
      }
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <Layout>
      <Seo title={page.name} />

      {typeof window !== "undefined" && loading ? (
        <PageLoader />
      ) : (
        <>
          {showMessage && (
            <div className="w-1/2 mx-auto flex flex-col justify-center items-center pt-16">
              <p className="text-3xl text-gray-600 text-center leading-snug mb-6">
                You must be logged in with a valid BSSR Membership to view this
                page.
              </p>

              <Button href="/login">Click Here to Sign In</Button>
            </div>
          )}

          {showErrMessage && (
            <div className="w-1/2 mx-auto flex flex-col justify-center items-center pt-16">
              <p className="text-3xl text-gray-600 text-center leading-snug">
                There was a problem loading this page. Please try again.
              </p>
            </div>
          )}

          {!showMessage && !showErrMessage && (
            <>
              <div className="z-0 w-full h-96 md:bg-indigo-900 md:border-t-8 border-gray-400" />
              <main className="z-10 container mx-auto -mt-96 md:-mt-80 xl:-mt-64">
                <div className="aspect-w-16 aspect-h-9 bg-black md:rounded lg:shadow-md">
                  {page.videoId && (
                    <iframe
                      className="object-cover"
                      title="vimeo-player"
                      src={`https://player.vimeo.com/video/${page.videoId}`}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allowFullScreen
                    />
                  )}
                </div>

                <div className="max-w-4xl my-12 mx-3 lg:mx-8 xl:mx-16">
                  <h1 className="serif text-xl lg:text-2xl xl:text-5xl font-extrabold mb-2">
                    {page.name}
                  </h1>

                  {/* Add Author to the Schema */}
                  {page.author && (
                    <p className="font-bold prose-lg lg:prose-xl mb-5">
                      {page.author}
                    </p>
                  )}

                  <PortableText blocks={page._rawAbstract} />
                </div>
              </main>
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default VideoTemplate

export const query = graphql`
  query ($id: String) {
    page: sanityVideo(id: { eq: $id }) {
      name
      author
      _rawAbstract
      slug {
        current
      }
      videoId
      restricted
    }
  }
`
