import React from "react"

import ClipLoader from "react-spinners/ClipLoader"

export default function PageLoader() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ClipLoader size={80} color="#3c366b" />
    </div>
  )
}
